import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import InsightPageContent from "../molecules/InsightPage/InsightPageContent";
import InsightPageHeader from "../molecules/InsightPage/InsightPageHeader";
import InsightPageRelated from "../molecules/InsightPage/InsightPageRelated";
import InsightPageSub from "../molecules/InsightPage/InsightPageSub";
import ImageBanner from "../components/atoms/banner/imageBanner";
import SubscribeNow from "../molecules/Insights/SubscribeNow";
import { Fade } from "react-reveal";

const InsightPage = ({
  pageContext: {
    id,
    slug,
    title,
    metaTitle,
    metaDescription,
    metaKeywords,
    ogTitle,
    ogDescription,
    subTitle,
    writtenBy,
    insightText,
    image,
    date,
    typeOfInsight,
    callToAction,
    imageAltText,
  },
}) => {
  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        canonicalLink={`https://www.goken-global.com/insight/${slug}`}
        title={metaTitle ? metaTitle : `Goken | ${title}`}
        description={metaDescription ? metaDescription : "Goken Insights"}
        facebookTitle={ogTitle ? ogTitle : title}
        facebookDescription={ogDescription ? ogDescription : "Goken Insights"}
        keywords={metaKeywords}
      ></SEO>
      <Fade duration={1000}>
        <div className="insights-page-outer-wrapper">
          {typeOfInsight === "CASE_STUDIES" ? null : <SubscribeNow />}
          {InsightPage && (
            <InsightPageHeader
              title={title}
              subTitle={subTitle}
              author={writtenBy}
              image={image ? image.url : ""}
              date={date ? date : ""}
              imageAltText={imageAltText}
            ></InsightPageHeader>
          )}
          <InsightPageContent insightText={insightText}></InsightPageContent>

          <InsightPageRelated relatedBlogs={[]}></InsightPageRelated>
        </div>
      </Fade>

      {typeOfInsight === "CASE_STUDIES" ? (
        <ImageBanner
          heading={callToAction ? callToAction : ""}
          btnText="Get in Touch"
          link="/contactus/"
        ></ImageBanner>
      ) : null}
      <br></br>
      <br></br>
    </Layout>
  );
};

export default InsightPage;
