import React from "react";

const InsightPageContent = (insightText) => {
  return (
    <div className="section">
      <div className="container">
        <div className="insight-page-content">
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: insightText.insightText.html }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default InsightPageContent;
