import React, { useState } from "react";
import { useForm } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";
import speakerImage from "../../images/bhonga.png";
import { Fade } from "react-reveal";
import downArrow from "../../images/downArrow.png";
import axios from "axios";
const SubscribeNow = () => {
  const { register, handleSubmit } = useForm();
  const [hide, setHide] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const closHandler = (e) => {
    try {
      console.log(e);
      console.log(e);
      let child = e.target;
      let close = child.closest(".subscribe-now-newsletter-wrapper");
      close.classList.add("hidden-box");
      console.log(close);
    } catch (error) {
      console.log(error);
    }
  };

  const [showMessage, setShowMessage] = useState(false);

  const onSubmit = (data) => {
    setIsLoading(true);
    const { email } = data;
    // let server = "http://localhost:8000";
    let server = "https://contactus.goken-global.com";
    const configs1 = {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    };
    const params = new URLSearchParams();
    params.append("email", email);
    params.append("segment", 28);

    axios
      .post(server + "/webapp/subscribe", params, configs1)
      .then((response) => {
        console.log(response.data.message);
        setIsLoading(false);
        setShowMessage(true);

        // navigate("/thankyou");
        // setMessage(response.data.message);
        // setLoad(false);
        // setShowSubmission(true);
      })
      .catch((Error) => {
        if (Error.message === "Network Error") {
          setShowMessage(true);
          setIsLoading(false);
        } else {
          console.log(Error);
          setIsLoading(false);
        }
        // setShowError(true);
        // setError("Something Went Wrong. Please try again after sometime.");
        // setLoad(false);
      });
  };
  return (
    <div className="subscribe-now-newsletter-wrapper">
      {showMessage ? (
        <div className="subscriber-form semi-para regular">
          <h3 className="semi-para regular">
            Thank you for subscribing to updates
          </h3>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="subscriber-form">
          <div className="para font-semi-bold newsletter-heading titlecase-text">
            Subscribe to receive our updates
            <div
              className="close-btn"
              onClick={() => {
                setHide(!hide);
              }}
            >
              <img
                src={downArrow}
                className={`hide-arrow ${hide ? "rotate-180" : ""}`}
                alt="downicon"
              />
            </div>
          </div>
          {/* <div className="semi-para newsletter-para common-bottom-space uppercase-text">
            join our subscriber list to receive monthly articles, news and
            updates!
          </div> */}

          <div
            className={`newsletter-email-wrapper ${hide ? "hide-below" : ""}`}
          >
            <div className="newsletter-email-inner">
              <input
                type="text"
                className="semi-para common-bottom-space"
                id="newsletter-email"
                placeholder="Enter Your Email Id"
                {...register("email", { required: true })}
              />
              <button className="semi-para titlecase-text">Subscribe</button>
              {isLoading && (
                <div className="col loading-text">
                  <div className="loader"></div>
                </div>
              )}
            </div>

            <img src={speakerImage} alt="speaker-image" />
          </div>
          {/* )}
          </Fade> */}
        </form>
      )}
    </div>
  );
};
export default SubscribeNow;
