import React from "react";

const InsightPageHeader = ({
  title,
  author,
  image,
  date,
  subTitle,
  imageAltText,
}) => {
  console.log(subTitle);
  return (
    <div className="section">
      <div className="container">
        <div className="insight-page-header ">
          <h1 className="bold-heading header-bottom-space">{title}</h1>

          {subTitle && <h2 className="para titlecase-text">{subTitle}</h2>}

          <div className="info m-top-20px">
            <div className="writtenby">
              {author ? (
                <>
                  Written By : <a href="/">{author}</a>
                </>
              ) : (
                ""
              )}
            </div>
            {/* <div className="date">Posted On {date}</div> */}
          </div>
          {image ? (
            <div className="insight-image-wrapper">
              <img
                src={image}
                alt={`${imageAltText ? imageAltText : title}`}
              ></img>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default InsightPageHeader;
